import { Modal } from 'bootstrap';

$(document).ready(function() {

  $('.pass-eye').click(function () {
    var container = $(this).parent();
    var input = container.find('input');
    if (input.attr('type') == 'password') {
        var rep = $("<input type='" + 'text' + "' />")
            .attr("id", input.attr("id"))
            .attr("name", input.attr("name"))
            .attr('class', input.attr('class'))
            .val(input.val())
            .insertBefore(input);
        input.remove();
    } else if (input.attr('type') == 'text') {
        var rep = $("<input type='" + 'password' + "' />")
            .attr("id", input.attr("id"))
            .attr("name", input.attr("name"))
            .attr('class', input.attr('class'))
            .val(input.val())
            .insertBefore(input);
        input.remove();
    }
  });

  $('.grl-login').on('click',function(e){
    e.preventDefault();
    $('#grlloginmodal').modal('show')
  })

  $('.grl-register').on('click',function(e){
    e.preventDefault();
    $('#grlregistermodal').modal('show')
  })

 
  $('.grl-login-submit').on('click',function(e){
    e.preventDefault();
    $('.be-error').hide();
    $('.error').hide();
    let thisform = $(this).closest('form');
    $('.form-control-wrap',$(thisform)).removeClass('form-control-wrap--error')
    $(this).closest('.grl-login').addClass('form-validate');
    let isValid = true;
    $('.form-control',$(thisform)).each(function() {
      if ($(this).is(":invalid")) {
        $(this).closest('.form-control-wrap').addClass('form-control-wrap--error')
        $(this).closest('.form-group').find('.error').show();
        isValid = 0;
      }
    });
    if (isValid) {
      $('.loading-loader').show();
      let data = {
				action : "grl_login_user",
        email : $('.grl-login .form-control[name="email"]').val(),
        pass : $('.grl-login .form-control[name="pass"]').val(),
        cart : $('.grl-login .form-control[name="cart"]').val(),
				}
      $.ajax({
        method: "POST",
        url: ajax_url,
        type: "post",
        dataType: "json",
        data: data
      }).done(function (response) {
        if (response['sucess'] == 'sucess') {
          //window.location.href = response['url'];
          if (response['cart'] == 1) {
            window.location.href = response['url'];
          } else {
            location.reload(true);
          }
          
        } else {
          //console.log(response);
          $.each(response, function (key, val) {
            $('.form-control[name="'+key+'"]',$(thisform)).closest('.form-control-wrap').addClass('form-control-wrap--error')
            $('.form-control[name="'+key+'"]',$(thisform)).closest('.form-group').find('.be-error').text(val).show();
          })
          $('.loading-loader').hide();
        }
        
      });
    }
  })



  $('.grl-register-submit').on('click',function(e){
    e.preventDefault();
    $('.be-error').hide();
    $('.error').hide();
    let thisform = $(this).closest('form');
    $('.form-control-wrap',$(thisform)).removeClass('form-control-wrap--error')
    $(this).closest('.grl-register').addClass('form-validate');
    let isValid = true;
    $('.form-control',$(thisform)).each(function() {
      if ($(this).is(":invalid")) {
        $(this).closest('.form-control-wrap').addClass('form-control-wrap--error')
        $(this).closest('.form-group').find('.error').show();
        isValid = 0;
      }
    });
    if (isValid) {
      $('.loading-loader').show();
      $.ajax({
        method: "POST",
        url: ajax_url,
        type: "post",
        dataType: "json",
        data: thisform.serialize()
      }).done(function (response) {
        if (response['sucess'] == 'sucess') {
            
            window.location.href = response['url'];
            
        } else {
            var text = '<ul class="">';
            $.each(response, function (key, val) {
                text += '<li>' + val + '</li>';
            })
            text += '</ul>';
            //console.log(text);
            $('.be-reg-error',$(thisform)).html(text);
            $('.be-reg-error').show()
            $('.loading-loader').hide();
        }
        
      });
    }
  })




  
});