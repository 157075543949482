import $ from 'jquery';

import {changeajax} from './cart-change';

export function pseudoinputtrigger(){
  $('.pseudo-input-countbtn [name="quantity"]').on('change',function(e){
    let thisitem = $(this);
    let newval = $(this).val();
    let thischeckout = $(this).closest('.pseudo-input-count-wrap-checkout');
    if(thischeckout.length > 0) {
      changeajax(newval,thisitem);
      
    }

  })
  $('.pseudo-input-countbtn .plus').on('click',function(e){
    e.preventDefault();
    let inputval = $(this).closest('.quantity').find('input[type="number"]').val() *1;
    let inputmax = $(this).closest('.quantity').find('input[type="number"]').attr('max') *1;
    let newval = inputval+1;
    let thisitem = $(this);
    let thischeckout = $(this).closest('.pseudo-input-count-wrap-checkout');
    if(inputmax == 0) {
        
      if(thischeckout.length > 0) {
        changeajax(newval,thisitem);
        
      }else {
        $(thisitem).closest('.quantity').find('input[type="number"]').val(newval)
        //$(thisitem).closest('.quantity').find('.order-item-count').text(newval)
      }
    }else if(inputval < inputmax) {
      
      if(thischeckout.length > 0) {
        changeajax(newval,thisitem)
      }else {
        $(thisitem).closest('.quantity').find('input[type="number"]').val(newval)
        //$(thisitem).closest('.quantity').find('.order-item-count').text(newval) 
      }
    }
    //console.log(inputval);
    
  });

  $('.pseudo-input-countbtn .minus').on('click',function(e){
    e.preventDefault();
    let inputval = $(this).closest('.quantity').find('input[type="number"]').val() *1;
    let inputmin = $(this).closest('.quantity').find('input[type="number"]').attr('min') *1;
    let newval = inputval-1;
    let thisitem = $(this);
    let thischeckout = $(this).closest('.pseudo-input-count-wrap-checkout');
    if(inputval > inputmin) {
      if(thischeckout.length > 0) {
        changeajax(newval,thisitem)
        
      }else {
        $(thisitem).closest('.quantity').find('input[type="number"]').val(newval)
        //$(thisitem).closest('.quantity').find('.order-item-count').text(newval) 
      }
    }
    //console.log(inputval);
  })
}