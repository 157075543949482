import $ from 'jquery';

import Swal from 'sweetalert2';

import Toastify from 'toastify-js'
import {btnheaderbascet} from './btnheaderbascet';
import {removecaritems} from './remove-cart-item';
import {pseudoinputtrigger} from './pseudo-input-count';
import {cartcouponclick} from './cart-change';
import {stickyCat, stickyCatCart} from './stickyCat';

import "toastify-js/src/toastify.css" 
import '../../scss/helpers/_add-to-cart-succes-modal.scss';


export function single_add_cart_lemon(thisitem) {
  
    //let thisbtn = $(this);
    let sendtobe = true;
    
    let thisbutton = $(thisitem),
        $form = thisbutton.closest('form.add-to-cart'),
        id = thisbutton.val(),
        product_qty = $form.find('input[name=quantity]').val() || 1,
        product_id = $form.find('input[name=product_id]').val() || id,
        variation_id = $form.find('input[name=variation_id]').val() || 0,
        name = thisbutton.attr('data-title'),
        price = thisbutton.attr('data-price'),
        datacart = thisbutton.data('cart');
    $form.removeClass('was-validated')
    //console.log(datacart)
    if($form.find('input[name=variation_id]').length && ($form.find('input[name=variation_id]').val() == '' || $form.find('input[name=variation_id]').val() == 0)) {
      sendtobe = false;
      $form.addClass('was-validated')
    }
    var data = {
      action: 'ajax_add_to_cart_lemon',
      product_id: product_id,
      product_sku: '',
      quantity: product_qty,
      variation_id: variation_id,
    };
    if(sendtobe) {
      $('.loading-loader').show();
      $.ajax({
        type: 'post',
        url: ajax_url,
        data: data,
        success: function (response) {
          
          if (response.success ) {
            if(response.itemscount == 1 && datacart == true){
              window.location.reload();
              return;
            }else {
              $('.cartbutton-wrap').html(response.cartbutton);
              $('.cartbutton-mobile-wrap').html(response.cartbuttonmobile);
              if(datacart != true) {
                const htmlContent = response.toast;
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, 'text/html');
                Toastify({
                  node: doc.body.firstChild,
                  duration: 3000,
                  //destination: "https://github.com/apvarun/toastify-js",
                  //newWindow: true,
                  close: true,
                  gravity: "top", // `top` or `bottom`
                  position: "right", // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  onClick: function(){} // Callback after click
                }).showToast();
              
              }
              
              $('.checkout-shopcart-wrap').html(response.orderitems);
              $('.cart-sumar-wrap').html(response.cartotal);

              btnheaderbascet();
              removecaritems();
              
              cartcouponclick();
            }

            if(datacart == true) {
              pseudoinputtrigger();
              $.each(stickyCat,function(index, value){
                stickyCat[index].updateSticky();
              })
              stickyCatCart[1].updateSticky();
            }

            window.dataLayer = window.dataLayer || [];
            if (dataLayer) {
              dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
              dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                  'currency': 'EUR',                            // 'add' actionFieldObject measures.
                  'items': [{                        //  adding a product to a shopping cart.
                    'name': name,
                    'currency': 'EUR',
                    'id': product_id,
                    'price': price,
                    'variant': variation_id,
                    'quantity': product_qty
                  }]
                  
                }
              });  
            }
            
          } else {
            Swal.fire({
              showClass : {
                popup: 'add-to-cart  swal2-show',
              },
              html: response.modal,
              showCloseButton: true,
              closeButtonHtml : '<i class="icon-cross"></i>',
              showCancelButton: false,
              showConfirmButton: false,
              focusConfirm: false,
              confirmButtonText : 'Odstrániť produkt z košíka',
              cancelButtonText : 'Pokračovať v nákupe',
              buttonsStyling : false,
              customClass: {
                confirmButton: 'btn btn-black  ',
                denyButton: '',
                cancelButton: 'btn  btn-primary',
              }
            })

            $('.swal2-cancel').on('click',function(e){
              e.preventDefault()
              Swal.close()
            })
          }
          $('.loading-loader').hide();
        },
        error: function(response) {
          //console.log(data)
          $('.loading-loader').hide();
        }
      });  
    }
      
}