import $ from 'jquery';

import {removecaritems} from './partials/remove-cart-item';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import {single_add_cart_lemon} from './partials/add-to-cart';
import {btnheaderbascet} from './partials/btnheaderbascet';

import 'swiper/swiper.scss';  
import 'swiper/modules/pagination.scss'; 
import 'swiper/modules/navigation.scss'; 

import 'ion-rangeslider';

import './inputs';
import './login';

import '../scss/style.scss';

const ajax_url = lemon_par.ajax_url;

$(document).ready(function() {

  $('.single_add_cart_lemon').on('click',function(e){
  
    e.preventDefault();
    single_add_cart_lemon($(this));
  })

  removecaritems();
  btnheaderbascet();

  /*var lazyLoadInstance = new LazyLoad({
      elements_selector: ".lazy"
      // ... more custom settings?
  });*/

  $('.hamburger.mobile-menu').on('click',function(){
    //console.log('hamburger');
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('body').toggleClass('o-hidden')
    //$('.main-header').toggleClass('open')
  })

  $('.mobile-menu-wrap .has-children .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).toggleClass('active');
    $(this).closest('.has-children').find('>.sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  var scroll = 0;
  $(window).on('scroll',function(){
    let curscroll = $(window).scrollTop();
    if ((curscroll > 90) ) {
        $('.main-navigation').addClass('scroll');
        $('.top-menu').addClass('scroll');
    }else {
        $('.main-navigation').removeClass('scroll');
        $('.top-menu').removeClass('scroll');
        
    }
    scroll = curscroll;

  })

  $('.tab-wrap .tab-item ').on('click',function(){
    let thistab = $(this).data('tab')
    if(!$(this).hasClass('active')) {
      $('.tab-wrap .tab-item ').removeClass('active')
      $(this).addClass('active')
      $(this).closest('.tab-wrap').find('.tab-content').removeClass('active')
      $(this).closest('.tab-wrap').find('.tab-content'+thistab+'').addClass('active')
    }
  })

  $('.main-search-btn .search-open').on('click',function(e){
    e.preventDefault()
    $(this).closest('.main-search-btn').find('.search-wrap').addClass('show')
    $(this).closest('.main-search-btn').find('.form-control').focus()
  })

  $('.main-search-btn .search-wrap .search-close').on('click',function(e){
    e.preventDefault()
    console.log('close')
    $(this).closest('.main-search-btn').find('.search-wrap').removeClass('show')
  })

  var currentRequest = null;  
  $('.main-search-btn .search-wrap .form-control').on('keyup',function(e){
    e.preventDefault();
    $('.loading-loader').show();
    console.log('pisem')
    let thisval = $(this).val();
    var data = {
      action: 'girl_search',
      search: thisval
    };
    if(currentRequest != null) {
      currentRequest.abort();
    }
    currentRequest = $.ajax({
      type: 'post',
      url: ajax_url,
      data: data,
      success: function (response) {
        console.log(response);
        if(response.succes == true) {
          
          $('.main-search-btn .search-wrap').addClass('has-result')
          $('.main-search-btn .search-wrap .search-results').show();
          $('.main-search-btn .search-wrap .search-results').html(response.html)
        }else {
          $('.main-search-btn .search-wrap').removeClass('has-result')
          $('.main-search-btn .search-wrap .search-results').hide();
          $('.main-search-btn .search-wrap .search-results').html('')
        }
        
        $('.loading-loader').hide();
      },
    });
  })

 
  var galleryTop = new Swiper('.hp-slider .swiper', {
    modules: [Pagination],
    spaceBetween: 10,
    //loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });


  var mobileproductimage = new Swiper('.mobile-productimage-swiper', {
    modules: [Pagination],
    spaceBetween: 10,
    //loop: true,
    slidesPerView: 1,
    /*autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },*/
    pagination: {
      el: '.mobile-productimage-pagination',
      type: 'bullets',
    },
  });

  var relatedproduct = new Swiper('.related-products-swiper', {
    modules: [Pagination, Navigation],
    spaceBetween: 15,
    //loop: true,
    slidesPerView: 'auto',
    /*autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },*/
    navigation: {
      nextEl: '.related-products-swiper-next',
      prevEl: '.related-products-swiper-prev',
    },
    pagination: {
      el: '.related-products-swiper-pagination',
      type: 'bullets',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        
      },
      992: {
        slidesPerView: 3,
        
      },
    },
  });


  $('.mobile-panel-category').on('click',function(e){
    e.preventDefault();
    //console.log('clicam')
    //$('.mobile-pannel-wrap .mobile-category-wrap').show()
    $('.mobile-pannel-wrap .mobile-category-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  $('.mobile-category-wrap .close').on('click',function(e){
    e.preventDefault();
    //console.log('clicam')
    //$('.mobile-pannel-wrap .mobile-category-wrap').show()
    $('.mobile-pannel-wrap .mobile-category-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  $('.mobile-panel-filter').on('click',function(e){
    e.preventDefault();
    //console.log('clicam')
    //$('.mobile-pannel-wrap .mobile-category-wrap').show()
    $('.mobile-pannel-wrap .mobile-filter-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  $('.mobile-filter-wrap .close').on('click',function(e){
    e.preventDefault();
    //console.log('clicam')
    //$('.mobile-pannel-wrap .mobile-category-wrap').show()
    $('.mobile-pannel-wrap .mobile-filter-wrap').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  $('.mobile-category-list .has-children .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).toggleClass('active');
    $(this).closest('.has-children').find('.sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })


  $('.js-range-slider').each(function(){
    let inputwrap = $(this).closest('.range-wrap')
    let thisform = $(this).closest('form');
    let inputmin = $('.form-group #min',inputwrap);
    let inputmax = $('.form-group #max',inputwrap);
    let min = $(this).data('min');
    let max = $(this).data('max');
    let instance;
    //console.log(min,max)
    $(this).ionRangeSlider({
        skin: "big",
        grid: false,
        grid_snap: false,
        step: 2,
        //hide_from_to: true,
        onStart: function (data) {
            $(inputmin).prop("value", data.from);
            $(inputmax).prop("value", data.to);
        },
        onChange: function (data) {
          $(inputmin).prop("value", data.from);
          $(inputmax).prop("value", data.to);
          
        },
        onFinish: function() {
          $(thisform).submit()
        }
    });

    instance = $(this).data("ionRangeSlider");

    $(inputmin).on("change", function () {
      let val = $(this).prop("value");
      //console.log(val);
      // validate
      if (val < min) {
          val = min;
          $(this).prop("value",val);
      } else if (val > max) {
          val = max;
          $(this).prop("value",val);
      }
      
      instance.update({
          from: val
      });
    });
    $(inputmax).on("change", function () {
        let val = $(this).prop("value");
        //console.log(val);
        // validate
        if (val < min) {
            val = min;
            $(this).prop("value",val);
        } else if (val > max) {
            val = max;
            $(this).prop("value",val);
        }
        
        instance.update({
            to: val
        });
    });

  })

  let url = window.location;
  let urlorigin = window.location.origin;
  let urlsearch = window.location.search;
  let urloriginhref = window.location.origin + window.location.pathname;
  if (urlsearch.indexOf("ajaxproducts") != -1) {
    console.log('ajax')
    ajaxfilter(urlsearch,urloriginhref)
  }

  $('.filter-attributes-wrap .form-control:not(.js-range-slider)').on('change',function(){
    let thisval = $(this).val();
    let thisname = $(this).attr('name');
    $('[name="'+thisname+'"]').val(thisval)
    $(this).closest('.filter-attributes-wrap').find('form').submit();
  })
  var currentRequestPorduct = null; 
  $('.ajaxproductform').submit(function(e){
    e.preventDefault();
    $('.loading-loader').show();

    let dataserialized = $(this).serialize()
    //console.log(data);
    if(currentRequestPorduct != null) {
      currentRequestPorduct.abort();
    }
    currentRequestPorduct = $.ajax({
        url : ajax_url,
        data : dataserialized,
        success : function(response) {
          //console.log(response);
          $('.productrow').html(response);
          $('.woocommerce-pagination').remove()
          window.history.pushState({href: urloriginhref+'?'+dataserialized}, '', urloriginhref+'?'+dataserialized);
          $('.loading-loader').hide();
          
        },
        error: function(response) {
          //$('.loading-loader').hide();
          //console.log(response)
        }
    });
  })

  $('.reset-filter-wrap a').on('click',function(e){
    e.preventDefault();
    $('.ajaxproductform .form-control').each(function(){
      $(this).val('0');
    })
    $('.ajaxproductform .form-control[name="pricemin"]').val(0);
    $('.ajaxproductform .form-control[name="pricemin"]').trigger('change');
    $('.ajaxproductform .form-control[name="pricemax"]').val(100000000);
    $('.ajaxproductform .form-control[name="pricemax"]').trigger('change');
    
    $('.ajaxproductform').submit();
  })


  

});

function ajaxfilter(urlsearch,urloriginhref){
  //e.preventDefault();
  $('.loading-loader').show();
  urlsearch = urlsearch.replace('?','');
  let urldata = urlsearch.split('&')
  let newdata = [];
  //console.log(urldata)
  $.each(urldata, function(i){
    newdata[i] = urldata[i].split('=');
    $('[name="'+newdata[i][0]+'"]').val(newdata[i][1])
    $('[name="'+newdata[i][0]+'"]').trigger('change')
  })


  $.ajax({
      url : ajax_url,
      data : urlsearch,
      success : function(response) {
        //console.log(response);
        $('.productrow').html(response);
        $('.woocommerce-pagination').remove()
        window.history.pushState({href: urloriginhref+'?'+urlsearch}, '', urloriginhref+'?'+urlsearch);
        $('.loading-loader').hide();
        
      },
      error: function(response) {
        $('.loading-loader').hide();
          console.log(response)
      }
  });
}

function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
