$ = jQuery;
$(document).ready(function() {
  $('.form-group .form-control-wrap .form-control').on('focusin',function(){
    //console.log('focus')
    $(this).closest('.form-group').find('.form-control-label').addClass('form-control-label--shrink')
    
  })

  $('.form-group .form-control-wrap .form-control:not(select)').on('focusout',function(){
    //console.log('focus ou')
    if($(this).val() =='') {
      $(this).closest('.form-group').find('.form-control-label').removeClass('form-control-label--shrink')
    }
    
  }) 

  $('.form-group .form-control-wrap .form-control').on('change paste keyup',function(){
    //console.log('focus ou')
    $(this).closest('.form-group').find('.form-control-label').addClass('form-control-label--shrink')
    
  }) 

  $('.pseudo-fileinput').on('click',function(e){
    e.preventDefault();
    $(this).closest('.form-control-wrap').find('.form-control').trigger('click');
  })

  

  $('.form-group .form-control-wrap .form-control').each(function(){
    if($(this).val() !='') {
      $(this).closest('.form-group').find('.form-control-label').addClass('form-control-label--shrink')
      $(this).closest('.form-group').find('.form-control-wrap').addClass('form-control-wrap--shrink')
    }
  }) 

  
})


